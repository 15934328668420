import { render, staticRenderFns } from "./static_pages.vue?vue&type=template&id=1a55bffc"
import script from "./static_pages.vue?vue&type=script&lang=js"
export * from "./static_pages.vue?vue&type=script&lang=js"
import style0 from "./static_pages.vue?vue&type=style&index=0&id=1a55bffc&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IsAdult: require('/app/components/isAdult.vue').default,CNotifications: require('/app/components/global/c-notifications.vue').default,VApp: require('/app/node_modules/vuetify/lib/components/VApp/VApp.js').default,Breadcrumbs: require('/app/components/global/breadcrumbs.vue').default,ModalChangeCity: require('/app/components/global/modals/modal-change-city.vue').default,Footer: require('/app/components/Footer.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
