import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=0c20c702&scoped=true"
import script from "./dashboard.vue?vue&type=script&lang=js"
export * from "./dashboard.vue?vue&type=script&lang=js"
import style0 from "./dashboard.vue?vue&type=style&index=0&id=0c20c702&prod&lang=scss&scoped=true"
import style1 from "./dashboard.vue?vue&type=style&index=1&id=0c20c702&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c20c702",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CNotifications: require('/app/components/global/c-notifications.vue').default,VApp: require('/app/node_modules/vuetify/lib/components/VApp/VApp.js').default,ModalChangeCity: require('/app/components/global/modals/modal-change-city.vue').default,Breadcrumbs: require('/app/components/global/breadcrumbs.vue').default,IconCreditCard: require('/app/components/icons/IconCreditCard.vue').default,DashSidebar: require('/app/components/dashboard/dash-sidebar.vue').default,Footer: require('/app/components/Footer.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
