
import { mapState } from "vuex";

export default {
  props: {
    id: Number,

    defaultMenus: {
      type: Boolean,
      default: true,
    },

    inDashboard: {
      type: Boolean,
      default: false,
    },

    inModelPage: {
      type: Boolean,
      default: false,
    },

    profile: {
      type: Object,
    },

    isFavorite: {
      type: Boolean,
      default: false,
    },

    isFavoriteProcessing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(["menus"]),

    generateLinkToTwitter() {
      let link;

      if (this.profile) {
        link = `http://twitter.com/share?text=Some text about this model&url=${this.$config.baseUrlApp}/${this.profile.slug}`;
      }

      return link;
    },

    isShowPm() {
      return (
        (this.$auth.loggedIn &&
          this.$auth.user.user_role === 3 &&
          !this.inDashboard &&
          !this.inModelPage) ||
        !this.$auth.loggedIn
      );
    },
  },

  methods: {
    redirectOnAuth() {
      if (window.innerWidth < 1024) {
        this.$root.$emit("sign-in-drawer:open");
      } else {
        this.$router.push({ name: "auth-signin" });
      }
    },

    sendPM() {
      if (this.$auth.loggedIn) {
        this.$emit("sendPM");
      } else {
        this.redirectOnAuth();
      }
    },

    openReportModal() {
      if (this.$auth.loggedIn) {
        this.$root.$emit("openReportProfileModal", this.profile);
      } else {
        this.redirectOnAuth();
      }
    },

    processFavorite() {
      if (this.$auth.loggedIn) {
        this.$emit("toggle-favorite");
      } else {
        this.redirectOnAuth();
      }
    },
  },
};
